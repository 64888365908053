import React, { Component } from 'react'
import styles from './AcitvityIndicator.module.css';

import classnames from 'classnames';
import { Spinner } from 'reactstrap';


class ActivityIndicator extends Component {

    GetVisibility() {
        if (this.props.visible)
            return styles.visible
        else
            return null
    }

    componentDidUpdate(oldProps){
        if(this.props.visible !== oldProps.visible)
            this.forceUpdate()
    }

    render() {
        return (
            <div className={classnames(this.props.className, styles.screen, "background-full", this.GetVisibility())} >
                    {/* <img className={styles.loader} color="#8F3484" src={loader} alt="loader"/> */}
                    <Spinner  size={10} className={styles.spinner}/>
            </div>
        )
    }
}

export default ActivityIndicator;