import React, { Component } from "react";
import NavBarComponent from "../NavBarComponents/NavBarComponent";
import "./CalendarComponent.scss";
import {
  getDaysInMonth,
  isSameDay,
  addMonths,
  subMonths,
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  getDay,
  getDate,
} from "date-fns";
import { Collapse } from "reactstrap";
import MeetingDropDownComponent from "../MeetingDropDownComponents/MeetingDropDownComponent";
import arrow from "../../Media/Images/Icon ionic-ios-arrow-down.png";
import meetings from "../../Data/MeetingData.json";
import redCircle from "../../Media/Images/redCircle.png";
import LinkButton from "../SharedComponents/LinkButton/LinkButton";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";

class CalendarComponent extends Component {
  state = {
    currentMonth: new Date(),
    bookedDates: [],
    currentDate: "",
    viewDetails: false,
    showDiv: false,
    meetingTitle: "",
    selectedDate: new Date(),
    selectedMeetings: meetings.meetings,
  };

renderloc(){}
  isBooked = (date: number | Date) => {
    var elements = meetings.meetings;
    var busyDateFormat = format(date, "dd MM yyyy").replaceAll(" ", "/");
    for (let index = 0; index < elements.length; index++) {
      var element = elements[index].Date;
      if (busyDateFormat == element) return true;
    }
  };

  nextMonth = () => {
    this.setState(() => ({
      currentMonth: addMonths(this.state.currentMonth, 1),
    }));
  };

  prevMonth = () => {
    this.setState(() => ({
      currentMonth: subMonths(this.state.currentMonth, 1),
    }));
  };


  renderHeader = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <div className="headerStyle">
        {/* <div> */}
        <img src={arrow} onClick={this.prevMonth} className="leftArrow" />
        {/* {"<"} */}
        {/* </div> */}
        {/* </div> */}
        <div className="col col-center">
          <span className="monthStyle">
            {monthNames[this.state.currentMonth.getMonth()]}{" "}
            {this.state.currentMonth.getFullYear()}
          </span>
          <span className="booked"></span>
        </div>
        {/* <div > */}
        <img src={arrow} onClick={this.nextMonth} className="rightArrow" />
        {/* </div> */}
      </div>
    );
  };

  renderDays = () => {
    const dateFormat = "d";
    const days = [];
    let startDate = startOfWeek(this.state.currentMonth);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {addDays(startDate, i).toString().split(" ")[0]}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  onCellClick(e: any, day: Date) {
    var busyDateFormat = format(day, "dd MM yyyy").replaceAll(" ", "/");
    var redcells = document.getElementsByClassName("redCell");
    for (let index = 0; index < redcells.length; index++) {
      redcells[index].classList.remove("redCell");
    }
    var formatedDate =
      day.toLocaleDateString("en-US", { weekday: "short" }) +
      ", " +
      day.toLocaleDateString("en-US", { day: "numeric" });
    this.setState(
      {
        currentDate: formatedDate,
        selectedDate: busyDateFormat,
        selectedMeetings: meetings.meetings.filter(
          (x) => x.Date == busyDateFormat.toString()
        ),
        viewDetails: true,
      },
      () => {}
    );
    var cell = document.getElementById("id_" + format(day, "dd MM").replaceAll(" ", "-"));
    cell?.classList.add("redCell");
  }
  renderMeetings() {
    var result = this.state.selectedMeetings.map((z) => {
      return (
        <MeetingDropDownComponent meetingInfo={z}></MeetingDropDownComponent>
      );
    });
    return result;
  }
  renderCells = () => {
    const { currentMonth } = this.state;
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const dateFullFormat = "dd MM";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    let fullformattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = "";
        formattedDate = format(day, dateFormat);
        fullformattedDate = format(day, dateFullFormat).replaceAll(" ", "-");
        const cloneDay = day;
        days.push(
          <div
            onClick={(e) => this.onCellClick(e, cloneDay)}
            id={"id_" + fullformattedDate}
            className={`col cell ${
              !isSameMonth(day, monthStart) ? "disabled" : ""
            }`}
            key={day.toString()}
          >
            <span className="number">{formattedDate}</span>
            {this.isBooked(day) ? (
              <img src={redCircle} className="redCircleStyle" />
            ) : (
              ""
            )}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="rowStyle" key={day.toString()}>
          {days}
        </div>
      );

      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  returnSuperScriptDates() {
    var date = this.state.selectedDate.toString().substring(0, 2);
    var number = parseInt(date);
    var j = number % 10,
      k = number % 100;
    if (j == 1 && k != 11) {
      return <sup>st</sup>;
    }
    if (j == 2 && k != 12) {
      return <sup>nd</sup>;
    }
    if (j == 3 && k != 13) {
      return <sup>rd</sup>;
    }
    return <sup>th</sup>;
  }

  render() {
    return (
      <div>
        <NavBarComponent navTitle="Calendar Page" />
        <div className="flexDiv">
          <div className="calendarWrapper">
            {this.renderHeader()}

            <div className="calendar">
              {this.renderDays()}
              {this.renderCells()}
            </div>
          </div>
          {this.state.viewDetails ? (
            <div className="rightPanel">
              <div className="dateStyle">
                {this.state.currentDate}
                {this.returnSuperScriptDates()}
              </div>
              {this.state.selectedMeetings.length > 0
                ? this.renderMeetings()
                : null} 
                <div className="btnWrapper">
                 <LinkButton className="btnStyle" to='/home' state='newMeeting'>New Meeting</LinkButton>
                 </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default CalendarComponent;
