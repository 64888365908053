import React, { Component } from 'react';
import NavBarComponent from '../NavBarComponents/NavBarComponent';
import LinkButton from '../SharedComponents/LinkButton/LinkButton';
import TableComponent from '../TableComponents/TableComponent';
import styles from './MembersListComponent.module.scss';
import list from '../../Data/ListOfMembers.json';
import { TableDTO } from '../../Models/TableDTO';

class MembersListComponent extends Component {
    returnTitle(){
        var title:TableDTO  ={
            firstHeader : "Name",
            middleHeader:"E-Mail",
            lastHeader:"Actions",
            firstCol:"name",
            secondCol:"mail"
    }
        return title;
    }
    render() {
        return (
            <div>
                 <NavBarComponent navTitle="Members" />
                 <div className={styles.mainDiv}>
                     <div className={styles.tableDiv}>
                         <div className={styles.flexDiv}>
                             <div className={styles.displayFlex}>
                                 <div className={styles.inputDiv}>
                                 <input type='radio' name="category" className={styles.radioStyle} defaultChecked/>
                                 <label className={styles.leftLabel}>Admins</label>
                                 </div>
                                 <div className={styles.inputDiv}>
                                 <input type='radio' name="category" className={styles.radioStyle}/>
                                 <label className={styles.rightLabel}>Members</label>
                                 </div>
                             </div>
                             <div className={styles.btnsDiv}>
                                 <LinkButton className={styles.leftBtnStyle}>New Member</LinkButton>
                                 <LinkButton className={styles.btnStyle}>Export Members</LinkButton>
                             </div>
                         </div>
                         <div>
                             <TableComponent
                             list={list}
                             tableProps={this.returnTitle()}
                             ></TableComponent>
                         </div>
                     </div>
                 </div>
            </div>
        );
    }
}

export default MembersListComponent;