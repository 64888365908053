import React, { Component } from "react";
import NavBarComponent from "../NavBarComponents/NavBarComponent";
import styles from "./MeetingListComponent.module.scss";
import { addMonths, subMonths } from "date-fns";
import arrow from "../../Media/Images/Icon ionic-ios-arrow-down.png";
import list from "../../Data/ListOfMeetings.json";
import PopupComponent from "../SharedComponents/PopupComponent/PopupComponent";
import NewMeetingTiles from "../NewMeetingComponents/NewMeetingTiles";
import { popupInfoStyle } from "../../Models/popupInfoStyle";
import close from "../../Media/Images/Icon ionic-ios-close-circle-outline.png";
import ShowMeetingDetailsComponent from "../ShowMeetingDetailsComponents/ShowMeetingDetailsComponent";
import TableComponent from "../TableComponents/TableComponent";
import { TableDTO } from "../../Models/TableDTO";
import topics from "../../Data/ListOfTopics.json";

type states = {
  currentMonth: Date;
  showPopup: boolean;
  selectedMeeting: string;
  selectedMeetingDate: string;
  selectedMeetingTime: string;
  showTopicsPopup: boolean;
};

class MeetingListComponent extends Component<{}, states> {
  constructor(props: {}) {
    super(props);
    this.state = {
      ...this.state,
      currentMonth: new Date(),
      showPopup: false,
    };
  }

  nextMonth = () => {
    this.setState(() => ({
      currentMonth: addMonths(this.state.currentMonth, 1),
    }));
  };

  prevMonth = () => {
    this.setState(() => ({
      currentMonth: subMonths(this.state.currentMonth, 1),
    }));
  };
  renderHeader = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <div className={styles.headerStyle}>
        <img src={arrow} onClick={this.prevMonth} className="leftArrow" />
        <div className="col col-center">
          <span className="monthStyle">
            {monthNames[this.state.currentMonth.getMonth()]}{" "}
            {this.state.currentMonth.getFullYear()}
          </span>
          <span className="booked"></span>
        </div>
        <img src={arrow} onClick={this.nextMonth} className="rightArrow" />
      </div>
    );
  };
  renderTableHeader() {
    return (
      <div className={styles.titleDiv}>
        <p className={styles.titleStyle}>Title</p>
        <p className={styles.centeredTitle}>Date</p>
        <p className={styles.centeredTitle}>Time</p>
        <p className={styles.centeredTitle}>Reports</p>
        <p className={styles.centeredTitle}>Actions</p>
      </div>
    );
  }
  renderTable(title: string, date: string, time: string) {
    return (
      <div className={styles.rowDiv}>
        <p
          className={styles.cellStyle}
          onClick={() => this.openPopup(title, date, time.substring(0, 2))}
        >
          {title}
        </p>
        <p className={styles.middleCell}>{date}</p>
        <p className={styles.middleCell}>{time}</p>
        <div className={styles.btnsDiv}>
          <button className={styles.btnStyle}>Attendance</button>
          <button className={styles.btnStyle}>Voting</button>
          <button className={styles.btnStyle}>Members</button>
        </div>
        <div className={styles.voteBtnStyle}>
          <button
            className={styles.voteBtn}
            onClick={() => this.openTopicsPopup()}
          >
            Vote
          </button>
        </div>
      </div>
    );
  }
  openPopup(title: string, date: string, time: string) {
    this.setState({
      selectedMeeting: title,
      selectedMeetingDate: date,
      selectedMeetingTime: time,
      showPopup: true,
    });
  }
  popUpClose() {
    this.setState({
      showPopup: false,
    });
  }
  renderPopupContent() {
    return (
      <div className={styles.popupDiv}>
        <img
          src={close}
          className={styles.closeStyle}
          onClick={() => this.popUpClose()}
        />
        <ShowMeetingDetailsComponent
          meetingTime={this.state.selectedMeetingTime}
          meetingTitle={this.state.selectedMeeting}
          meetingDate={this.state.selectedMeetingDate}
          onBtnClick={()=>this.popUpClose()}
        />
      </div>
    );
  }
  topicPopUpClose() {
    this.setState({
      showTopicsPopup: false,
    });
  }

  returnTopicsTableContent() {
    var table: TableDTO = {
      firstHeader: "Title",
      middleHeader: "Description",
      lastHeader: "Vote",
      firstCol: "title",
      secondCol: "description",
      lastCol: (
        <div>
          <button className={styles.agreeBtn}>Agree</button>
          <button className={styles.disagreeBtn}>Disagree</button>
        </div>
      ),
    };
    return table;
  }

  renderTopicsPopupContent() {
    return (
      <div className={styles.popupWrapper}> 
      <img
          src={close}
          className={styles.topicsPopupClose}
          onClick={() => this.topicPopUpClose()}
        />    
         <div className={styles.topicsPopupDiv}>
        <p className={styles.popupTitle}>Topics</p>
        <TableComponent
          tableProps={this.returnTopicsTableContent()}
          list={topics}
        />
      </div>
      </div>

    );
  }

  openTopicsPopup() {
    this.setState({
      showTopicsPopup: true,
    });
  }
  render() {
    return (
      <div>
        <PopupComponent
          show={this.state.showPopup}
          onClose={() => this.popUpClose()}
          className={styles.popUp}
          popUpContent={this.renderPopupContent()}
        ></PopupComponent>
        <PopupComponent
          show={this.state.showTopicsPopup}
          onClose={() => this.topicPopUpClose()}
          className={styles.popUp}
          popUpContent={this.renderTopicsPopupContent()}
        ></PopupComponent>
        <NavBarComponent navTitle="Meetings List" />
        <div className={styles.mainDiv}>
          {this.renderHeader()}
          {this.renderTableHeader()}
          {list.map((x) => {
            if (
              this.state.currentMonth.getMonth() + 1 ==
              parseInt(x.date.substring(3, 5))
            )
              return this.renderTable(x.meetingTitle, x.date, x.time);
          })}
        </div>
      </div>
    );
  }
}

export default MeetingListComponent;
