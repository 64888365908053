import React, { Component } from "react";
import NavBarComponent from "../NavBarComponents/NavBarComponent";
import LinkButton from "../SharedComponents/LinkButton/LinkButton";
import styles from "./HomeContent.module.scss";
import data from "../../Data/MeetingData.json";
import ActivityIndicator from "../SharedComponents/ActivityIndicatorComponent/ActivityIndicator";
import ProfilePic from '../../Media/Images/profile.png'

type State = {
  isGraphLoaded: boolean
}

class HomeContent extends Component<{}, State> {

  state = {
    isGraphLoaded: false
  }

  dates(current: any) {
    var week = new Array();
    current.setDate(current.getDate() - current.getDay());
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return week;
  }
  returnMeetingDates() {
    var dates = data.meetings.map((x) => {
      return x.Date;
    });
    return Array.from(new Set(dates));
  }
  returnDays() {
    var week = this.dates(new Date());
    var dayNameDiv = document.getElementById("dayName");
    var dayDate = document.getElementById("dayDate");
    for (let index = 0; index < week.length; index++) {
      var dayname = week[index].toString().split(" ")[0].substring(0, 1);
      var daynum = week[index].toString().split(" ")[2];

      var weekdaysdiv = document.createElement("div");
      weekdaysdiv.innerHTML = dayname;
      dayNameDiv!.appendChild(weekdaysdiv);
      var daydatediv = document.createElement("div");
      var dayDateDivInner = document.createElement("div");
      dayDateDivInner.classList.add(styles.datesWrapper)
      dayDate?.appendChild(dayDateDivInner);
      daydatediv.innerHTML = daynum;
      dayDateDivInner!.appendChild(daydatediv);
      weekdaysdiv.classList.add(styles.weekdayStyle);
      daydatediv.classList.add(styles.daydateStyle);

      var meetings = this.returnMeetingDates();

      for (let i = 0; i < meetings.length; i++) {
        if (meetings[i].substring(0, 2) == daynum && parseInt(meetings[i].substring(3, 5)) == (new Date().getMonth() + 1)) {

          var busydiv = document.createElement("div");
          busydiv.classList.add(styles.blackDot);
          dayDateDivInner!.appendChild(busydiv)

        }
      }
    }
  }
  componentDidMount() {
    this.returnDays();
  }

  onIframeLoad() {
    setTimeout(() => this.setState({
      isGraphLoaded: true
    }), 3000)

  }

  render() {
    return (
      <div>
        <NavBarComponent navTitle="Home Page" />
        <div className={styles.wrapperDiv}>
          <div className={styles.welcomeDiv}>
            <div className={styles.gradientDiv}>
              <div className={styles.welcomeText}>
                <p className={styles.titleStyle}>Hello, Nasser Mohammed AlSubeaei</p>
                <p className={styles.subTitle}>Welcome Back...</p>
              </div>
              <div className={styles.circleDiv}>

                <img src={ProfilePic} />
              </div>
            </div>
          </div>
          <div className={styles.graphWrapper}>
            <p className={styles.graphBlockTitle}>Stock price</p>
            <div>
              <ActivityIndicator visible={!this.state.isGraphLoaded} />

              <iframe width="100%" height="450px"
                scrolling="no"
                onLoad={() => this.onIframeLoad()}
                src="https://tools.eurolandir.com/tools/sharegraph/?s=1788&companycode=sa-1140&lang=en-GB#shareGraph"
                style={{ visibility: this.state.isGraphLoaded ? 'visible' : "hidden" }} />
            </div>
          </div>
          <div className={styles.whiteBlocksWrapper}>
            <div className={styles.leftWhiteBlock}>
              <p className={styles.whiteBlockTitle}>Next Meeting</p>
              <div className={styles.nextMeetingBtn}>
                <p className={styles.nextMeetingBtnText}>Tuesday, 9:00 AM</p>
              </div>
            </div>
            <div className={styles.whiteBlock}>
              <p className={styles.whiteBlockTitle}>This Week</p>
              <div>
                <div id="dayName" className={styles.weeklyCalendarDiv}></div>
                <div id="dayDate" className={styles.weeklyCalendarDiv}></div>
              </div>
            </div>
          </div>
          <div className={styles.btnsWrapper}>
            <LinkButton className={styles.btnStyle} to='/home' state='newMeeting'>New Meeting</LinkButton>
            <LinkButton className={styles.btnStyle}>Voting Report</LinkButton>
            <LinkButton className={styles.btnBasicStyle}>
              Attendance Report
            </LinkButton>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeContent;
