import React, { Component } from "react";
import styles from "./MeetingDropDownComponent.module.scss";
import arrow from "../../Media/Images/Icon ionic-ios-arrow-down.png";
import { Button, Collapse } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { meetingInfoDTO } from "../../Models/meetingInfoDTO";
import editIcon from '../../Media/Images/Icon awesome-edit.png';
import deleteIcon from '../../Media/Images/Icon metro-bin.png';

interface Props {
  meetingInfo: meetingInfoDTO;
}
interface states {
  isOpen: boolean;
}

class MeetingDropDownComponent extends Component<Props, states> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    var data = this.props.meetingInfo;
    return (
      <div>
        <div className={styles.mainDiv} onClick={() => this.setState({isOpen: !this.state.isOpen})}>
          <div className={styles.flexDiv}>
            <div className={styles.redCircle}></div>
            <div className={styles.title}>{data.Title}</div>
          </div>
          <img src={arrow} className={this.state.isOpen ? styles.rotatedArrow: styles.arrowStyle}/>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className={styles.expandedDiv}>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Date:</p><p className={styles.expandedDivText}>{data.Date}</p></div>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Time: </p><p className={styles.expandedDivText}>{data.Time}</p></div>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Location:</p><p className={styles.expandedDivText}>{data.Location}</p></div>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Notes:</p><p className={styles.expandedDivText}>{data.Notes}</p></div>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Topics:</p>{ 
            data.Topics.map(x=> {return <p className={styles.topicsDiv}>{x}</p>})}</div>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Attachments:</p><p className={styles.expandedDivText}><u>Click here to download...</u></p></div>
            <div className={styles.detailsDiv}><p className={styles.expandedDivTitle}>Members:</p>{ 
            data.Members.map(x=> {return <p className={styles.topicsDiv}>{x}</p>})}</div>
            <div className={styles.iconsDiv}>
                <img src={editIcon} className={styles.editIcon}/>
                <img src={deleteIcon}/>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default MeetingDropDownComponent;
