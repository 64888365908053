import React, { Component } from "react";
import styles from "./NavBarComponent.module.scss";
import notification from "../../Media/Images/notificationIcon.png";
import profile from "../../Media/Images/profileIcon.png";
import arrow from "../../Media/Images/Icon ionic-ios-arrow-down.png";
import ProfilePic from '../../Media/Images/profile.png'
import { Collapse } from "reactstrap";

interface Props {
  navTitle: string;
}
interface states {
    isOpen: boolean;
}

class NavBarComponent extends Component<Props, states> {
    constructor(props:Props) {
        super(props);
        this.state={
            isOpen: false
        }
    }
    
  render() {
    return (
      <div>
        <div className={styles.navBarDiv}>
          <p className={styles.title}>{this.props.navTitle}</p>
          <div className={styles.flexDiv}>
            <img src={notification} className={styles.notificationStyle} />
           <div className={styles.alignDiv}>
            <div className={styles.flexDiv} onClick={() => this.setState({
                isOpen: !this.state.isOpen
            })}>
              <div className={styles.profileStyle}>
                <img src={ProfilePic} />
              </div>
              <img src={arrow} className={styles.imgStyle} />
            </div>
            <Collapse isOpen={this.state.isOpen}>
              <div className={styles.dropdownMenu}>
                <p className={styles.menuItemStyle}>
                 Profile
                </p>
                <p className={styles.menuItemStyle}>
                  Settings
                </p>
                <p className={styles.menuItemStyle}>
                  Logout
                </p>
              </div>
            </Collapse>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBarComponent;
