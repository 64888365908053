import React, { Component } from "react";
import { Collapse } from "reactstrap";
import LinkButton from "../SharedComponents/LinkButton/LinkButton";
import styles from "./NewMeetingComponent.module.scss";
import add from "../../Media/Images/Icon awesome-plus-circle.png";
import save from "../../Media/Images/Procceed.png";
import { TopicsDTO } from "../../Models/TopicsDTO";

type states = {
  generalTab: boolean;
  topicsTab: boolean;
  newTopic: boolean;
  addTopic: boolean;
  title?: string;
  description: string;
};

const topics: TopicsDTO =[{}];

class NewMeetingTiles extends Component<{}, states> {
  constructor(props: {}) {
    super(props);
    this.state = {
      generalTab: true,
      topicsTab: false,
      newTopic: false,
      addTopic: true,
      title: "",
      description: "",
    };
  }

  returnFirstTile() {
    return (
      <div className={styles.tileStyle}>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Title</p>
          <input className={styles.inputStyle} />
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Day</p>
          <input className={styles.inputStyle} />
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Time</p>
          <div className={styles.timeDiv}>
            <p className={styles.subTitleStyle}>Hours</p>
            <input
              type="text"
              pattern="[0-9]+"
              maxLength={2}
              className={styles.timeInput}
            />
            {/* <input  type="hour"/> */}
            <p className={styles.subTitleStyle}>Minutes</p>
            <input className={styles.timeInput} />
          </div>
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Duration</p>
          <input className={styles.inputStyle} />
        </div>
      </div>
    );
  }
  returnMiddleTile() {
    return (
      <div className={styles.tileStyle}>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Location</p>
          <input className={styles.inputStyle} />
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Notes</p>
          <textarea className={styles.inputStyle} rows={5} />
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Attachament</p>
          <div className={styles.attachmentInpuStyle}>
            <label className={styles.attachmentTitle}>
              Drop files or browse from computer
            </label>
            <input type="file" className={styles.attachmentUpload} />
          </div>
        </div>
      </div>
    );
  }
  returnLastTile() {
    return (
      <div className={styles.lastTileStyle}>
        <p className={styles.titleStyle}>Add Members</p>
        <div className={styles.membersDiv}>
          <div className={styles.checkboxDiv}>
            <input type="checkbox" className={styles.checkboxStyle} />
            <label>First Name</label>
            <span className={styles.checkbox}></span>
          </div>
          <div className={styles.checkboxDiv}>
            <input type="checkbox" className={styles.checkboxStyle} />
            <label>First Name</label>
            <span className={styles.checkbox}></span>
          </div>
          <div className={styles.checkboxDiv}>
            <input type="checkbox" className={styles.checkboxStyle} />
            <label>First Name</label>
            <span className={styles.checkbox}></span>
          </div>
        </div>
        <LinkButton className={styles.btnStyle}>Schedule Meeting</LinkButton>
      </div>
    );
  }
  saveRow() {
    this.setState({
      addTopic: true,
      newTopic: false,
    });
    topics.push({
      title: this.state.title,
      description: this.state.description,
    });
    this.returnRow();
  }

  returnRow() {
      return topics.map((x) => {
        if(x.title !== undefined && x.description !== undefined){
        return (
          <div className={styles.topicsContentDiv}>
            <p className={styles.tableItemStyle}>{x.title}</p>
            <p className={styles.tableItemStyle}>{x.description}</p>
          </div>
        );
        }
      });
  }
  saveTitle(value?: string) {
    this.setState({
      title: value,
    });
  }
  saveDescription(value: string) {
    this.setState({
      description: value,
    });
  }

  render() {
    return (
      <div className={styles.mainDiv}>
        <div className={styles.displayFlex}>
          <div className={styles.inputDiv}>
            <input
              type="radio"
              name="category"
              className={styles.radioStyle}
              defaultChecked
              onChange={() =>
                this.setState({ topicsTab: false, generalTab: true })
              }
            />
            <label className={styles.leftLabel}>General</label>
          </div>
          <div className={styles.inputDiv}>
            <input
              type="radio"
              name="category"
              className={styles.radioStyle}
              onChange={() =>
                this.setState({ generalTab: false, topicsTab: true })
              }
            />
            <label className={styles.rightLabel}>Topics</label>
          </div>
        </div>
        <Collapse isOpen={this.state.generalTab}>
          <div className={styles.container}>
            {this.returnFirstTile()}
            {this.returnMiddleTile()}
            {this.returnLastTile()}
          </div>
        </Collapse>
        <Collapse isOpen={this.state.topicsTab}>
          <div className={styles.topicsContainer}>
            <p className={styles.topicsTitle}>Topics</p>
            <div className={styles.topicsTableHeaderDiv}>
              <p className={styles.topicsTableTitle}>Title</p>
              <p className={styles.topicsTableTitle}>Description</p>
            </div>
            <div className={styles.topicsTableDiv}>
              <Collapse isOpen={this.state.newTopic}>
                <div className={styles.newTopicDiv}>
                  <input
                    type="text"
                    className={styles.inputFieldStyle}
                    onChange={(e) => this.saveTitle(e.target.value)}
                  />
                  <textarea
                    className={styles.textAreaStyle}
                    onChange={(e) => this.saveDescription(e.target.value)}
                  />
                  <img src={save} onClick={() => this.saveRow()} />
                </div>
              </Collapse>
              {this.returnRow()}
              <Collapse isOpen={this.state.addTopic}>
                <div
                  className={styles.topicsInnerDiv}
                  onClick={() =>
                    this.setState({
                      newTopic: true,
                      addTopic: false,
                    })
                  }
                >
                  <img src={add} className={styles.addIcon} />
                  <p className={styles.contentTitle}>Add New Topic</p>
                </div>
              </Collapse>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default NewMeetingTiles;
