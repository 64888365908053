import React, { Component, CSSProperties } from 'react';
import styles from './PageContainer.module.css'
import classnames from 'classnames'
import ActivityIndicator from '../ActivityIndicatorComponent/ActivityIndicator'
import IPageTheme from '../../../Libs/Models/Interfaces/IPageTheme';

interface Props {
    className?: string,
    style?: CSSProperties
}

class PageContainer<P = {}, S = {}> extends Component<P & Props, S> {
    syncActivitiyCount: number;
    mainDiv: any;
    Theme?: IPageTheme<any>

    constructor(props: any) {
        super(props)
        this.syncActivitiyCount = 0
        this.mainDiv = React.createRef()
        this.initialize()
    }

    Activity = {
        Show: () => {
            this.syncActivitiyCount += 1
            this.forceUpdate()
        },
        Hide: () => {
            this.syncActivitiyCount = this.syncActivitiyCount > 0 ? this.syncActivitiyCount - 1 : 0
            this.forceUpdate()
        },
        ForceHide: () => {
            this.syncActivitiyCount = 0
            this.forceUpdate()
        }
    }

    initialize(): any {

    }

    renderContent(): any {

    }

    renderNavbar(): any {

    }

    SetTheme<T>(pageTheme: IPageTheme<T>) {
        this.Theme = pageTheme
    }

    renderActivity(visible: boolean) {
        return <ActivityIndicator className={styles.loader} visible={visible} />
    }

    renderSideMenu(): any {

    }

    render() {
        return (
            <main ref={r => this.mainDiv = r} className={classnames(styles.container, this.props.className)}>
                <div className={styles.scrollArea}>
                    <div className={styles.pageWrapper}>
                        <div className={styles.navBarContainer}>
                            {this.renderNavbar()}
                        </div>
                        <div className={styles.contentContainer}>
                            <div className={styles.content}>
                                {this.renderSideMenu()}
                                {this.renderContent()}
                            </div>
                        </div>
                        {this.renderActivity(this.syncActivitiyCount > 0)}
                    </div>
                </div>
            </main>
        );
    }
}


export default PageContainer;