import React, { Component } from "react";
import { TableDTO } from "../../Models/TableDTO";
import NavBarComponent from "../NavBarComponents/NavBarComponent";
import LinkButton from "../SharedComponents/LinkButton/LinkButton";
import TableComponent from "../TableComponents/TableComponent";
import styles from "./TopicsListComponent.module.scss";
import list from "../../Data/ListOfTopics.json";

class TopicsListComponent extends Component {
  returnTitle() {
    var title: TableDTO = {
      firstHeader: "Title",
      middleHeader: "Description",
      lastHeader: "Actions",
      firstCol: "title",
      secondCol: "description",
    };
    return title;
  }
  render() {
    return (
      <div>
        <NavBarComponent navTitle="Topics" />
        <div className={styles.mainDiv}>
          <div className={styles.tableDiv}>
            <div className={styles.btnsDiv}>
              <LinkButton className={styles.btnStyle}>Export Topics</LinkButton>
            </div>
            <div>
              <TableComponent
                list={list}
                tableProps={this.returnTitle()}
              ></TableComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopicsListComponent;
