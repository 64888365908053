import React, { Component } from "react";
import styles from "./TableComponent.module.scss";
import edit from "../../Media/Images/grey_Icon awesome-edit.png";
import bin from "../../Media/Images/grey_Icon metro-bin.png";
import info from "../../Media/Images/grey_Icon metro-info.png";
import { TableDTO } from "../../Models/TableDTO";

type Props = {
  tableProps: TableDTO;
  list: any;
};

class TableComponent extends Component<Props, {}> {
  renderTableHeader(
    firstHeader: string,
    middleHeader: string,
    lastHeader: string
  ) {
    return (
      <div className={styles.titleDiv}>
        <p className={styles.titleStyle}>{firstHeader}</p>
        <p className={styles.middleTitleStyle}>{middleHeader}</p>
        <p className={styles.centeredTitle}>{lastHeader}</p>
      </div>
    );
  }
  renderTable(title: any, data: any) {
    return (
      <div className={styles.rowDiv}>
        <p className={styles.cellStyle}>{title}</p>
        <p className={styles.cellStyle}>{data}</p>
        <div className={styles.btnsDiv}>
          {this.props.tableProps?.lastCol ? (
            this.props.tableProps?.lastCol
          ) : (
            <div>
              {" "}
              <img src={edit} />
              <img src={bin} />
              <img src={info} />
            </div>
          )}
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderTableHeader(
          this.props.tableProps.firstHeader,
          this.props.tableProps.middleHeader,
          this.props.tableProps.lastHeader
        )}
        {this.props.list.map((x: any) => {
          return this.renderTable(
            x[this.props.tableProps.firstCol],
            x[this.props.tableProps.secondCol]
          );
        })}
      </div>
    );
  }
}

export default TableComponent;
