import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Configuration from './Configs/Configuration';
import { Appstore } from './Mobx/Stores/AppStore';
import HomePage from './Pages/HomePage/HomePage';
import LoginPage from './Pages/LoginPage/LoginPage';

type Props = {
  App?: Appstore
}

@inject("App")
@observer
class App extends Component<Props> {

  constructor(props: Props) {
    super(props)
    Configuration.Configure()
  }

  renderGuest() {
    return (
      <div className="App">
        <BrowserRouter>

          <Routes>
            <Route
              path="/home" element={<HomePage />} />
            <Route
              path="/login" element={<LoginPage />} />
              <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
          
        </BrowserRouter>
      </div>


    );
  }
  render() {
    if (!this.props.App?.isAppStarted)
      return null

    return this.renderGuest()
  }

}

export default App;
