import WebClient from "../../Libs/WebClient/WebClient";
import { LoginDto } from "../../Libs/Models/Contracts/Auth/LoginDto";
import { WebClientResponse } from "../../Libs/WebClient/WebClientResponse";
import { GlobalStore } from "../Stores/MainStore";
import { AuthorizationResponseDto } from "../../Libs/Models/Contracts/Auth/AuthorizationResponseDto";


export class AuthActions {

    static Login(model: LoginDto) {

        return WebClient
            .Create()
            .Post<AuthorizationResponseDto>(`auth/login`, model)
            .onComplete((response?: WebClientResponse<AuthorizationResponseDto>) =>{
                GlobalStore.Auth.SetAuthorization(response!.data)
            })
    }
}