import React, { Component } from "react";
import logo from "../../Media/Images/LOGO.png";
import home from "../../Media/Images/Icon ionic-md-home.png";
import calendar from "../../Media/Images/Icon awesome-calendar-alt.png";
import topics from "../../Media/Images/Icon material-chat.png";
import meetings from "../../Media/Images/Icon material-event-available.png";
import members from "../../Media/Images/Icon material-people.png";
import profile from "../../Media/Images/Icon material-account-box.png";
import styles from "./HomePage.module.scss";
import HomeContent from "../../Components/HomeContentComponents/HomeContent";
import classNames from "classnames";
import CalendarComponent from "../../Components/CalendarComponents/CalendarComponent";
import NewMeetingComponent from "../../Components/NewMeetingComponents/NewMeetingComponent";
import { Location, RouteProps, useLocation } from "react-router";
import MeetingListComponent from "../../Components/MeetingListComponents/MeetingListComponent";
import z from "../../Components/SharedComponents/LinkButton/Navigation";
import PageContainer from "../../Components/SharedComponents/PageContainer/PageContainer";
import MembersComponent from "../../Components/MembersListComponents/MembersListComponent";
import TopicsListComponent from "../../Components/TopicsListComponents/TopicsListComponent";

interface Props {
  location?: Location;
  history?: History;
}
interface states {
  content: string;
}

class HomePage extends PageContainer<Props, states> {
  constructor(props: Props) {
    super(props);
    var x = window.history.state.state;
    this.state = {
      content: x ?? "home",
    };
  }

  onMenuChange(value: string) {
    this.setState({
      content: value,
    });
  }
  renderCurrentContent(value: string) {
    switch (value) {
      case "home":
        return <HomeContent />;
      case "calendar":
        return <CalendarComponent />;
      case "meetings":
        return <MeetingListComponent />;

      case "newMeeting":
        return <NewMeetingComponent />;
      case "members":
        return <MembersComponent />;
      case "topics":
        return <TopicsListComponent />;
      default:
        break;
    }
  }
  renderSideMenu() {
    return (
      <div className={styles.mainDiv}>
        <img src={logo} />
        <div className={styles.wrapperDiv}>
          <div
            className={classNames(
              styles.flexDiv,
              this.state.content == "home" ? styles.selectedBtn : null
            )}
          >
            <input
              type="radio"
              name="menu"
              className={styles.radioStyle}
              onChange={() => this.onMenuChange("home")}
              defaultChecked={this.state.content == "home" ? true : false}
            />
            <img src={home} className={styles.imgStyle} />
            <p className={styles.menuItem}>Home</p>
          </div>
          <div
            className={classNames(
              styles.flexDiv,
              this.state.content == "calendar" ||
                this.state.content == "newMeeting"
                ? styles.selectedBtn
                : null
            )}
          >
            <input
              type="radio"
              name="menu"
              className={styles.radioStyle}
              onChange={() => this.onMenuChange("calendar")}
            />
            <img src={calendar} className={styles.imgStyle} />
            <p className={styles.menuItem}>Calendar</p>
          </div>
          <div
            className={classNames(
              styles.flexDiv,
              this.state.content == "topics" ? styles.selectedBtn : null
            )}
          >
            <input
              type="radio"
              name="menu"
              className={styles.radioStyle}
              onChange={() => this.onMenuChange("topics")}
            />
            <img src={topics} className={styles.imgStyle} />
            <p className={styles.menuItem}>Topics</p>
          </div>
          <div
            className={classNames(
              styles.flexDiv,
              this.state.content == "meetings" ? styles.selectedBtn : null
            )}
          >
            <input
              type="radio"
              name="menu"
              className={styles.radioStyle}
              onChange={() => this.onMenuChange("meetings")}
            />
            <img src={meetings} className={styles.imgStyle} />
            <p className={styles.menuItem}>Meetings</p>
          </div>
          <div
            className={classNames(
              styles.flexDiv,
              this.state.content == "members" ? styles.selectedBtn : null
            )}
          >
            <input
              type="radio"
              name="menu"
              className={styles.radioStyle}
              onChange={() => this.onMenuChange("members")}
            />
            <img src={members} className={styles.imgStyle} />
            <p className={styles.menuItem}>Members</p>
          </div>
          <div className={styles.flexDiv}>
            <img src={profile} className={styles.imgStyle} />
            <p className={styles.menuItem}>Profile</p>
          </div>
        </div>
      </div>
    )
  }
  renderContent() {
    return (

      <div className={styles.content}>
        {this.renderCurrentContent(this.state.content)}
      </div>

    );
  }
}

export default HomePage;
