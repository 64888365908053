import React, { Component } from "react";
import LinkButton from "../SharedComponents/LinkButton/LinkButton";
import styles from "./ShowMeetingDetailsComponent.module.scss";
import file from '../../Media/Images/Icon awesome-file-alt.png';
import audio from '../../Media/Images/Icon awesome-file-audio.png';

type Props = {
  meetingTitle: string;
  meetingDate: string;
  meetingTime: string;
  onBtnClick?:React.MouseEventHandler<HTMLButtonElement>;
}

class ShowMeetingDetailsComponent extends Component<Props , {}> {
  returnFirstTile() {
    return (
      <div className={styles.tileStyle}>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Title</p>
          <input className={styles.inputStyle} placeholder={this.props.meetingTitle} disabled/>
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Day</p>
          <input className={styles.inputStyle} disabled placeholder={this.props.meetingDate}/>
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Time</p>
          <div className={styles.timeDiv}>
            <p className={styles.subTitleStyle}>Hours</p>
            <input className={styles.timeInput} type="hour" disabled placeholder={this.props.meetingTime}/>
            <p className={styles.subTitleStyle}>Minutes</p>
            <input className={styles.timeInput} disabled placeholder="00"/>
          </div>
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Attachament</p>
          <div className={styles.attachmentInpuStyle}>
            <div className={styles.colFlexDiv}>
              <img src={file} className={styles.iconStyle}/>
              <label className={styles.iconLabel}>Expenses.docs</label>
            </div>
            <div className={styles.colFlexDiv}>
            <img src={audio} className={styles.iconStyle}/>
              <label className={styles.iconLabel}>Audio.mp3</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
  returnMiddleTile() {
    return (
      <div className={styles.tileStyle}>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Location</p>
          <input className={styles.inputStyle} disabled placeholder="Skype"/>
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Notes</p>
          <textarea className={styles.inputStyle} rows={5} disabled/>
        </div>
        <div className={styles.wrapperDiv}>
          <p className={styles.titleStyle}>Topics</p>
          <div className={styles.topicsDiv}>
          <label className={styles.topicsTitle}>Marekting Plan</label>
          <label className={styles.topicsTitle}>IBAN Project</label>
          <label className={styles.topicsTitle}>Central Bank</label>
          <label className={styles.topicsTitle}>New ATMs</label>
          </div>
        </div>
      </div>
    );
  }
  returnLastTile() {
    return (
      <div className={styles.lastTileStyle}>
        <p className={styles.titleStyle}>Members</p>
        <div className={styles.memberInputStyle}>
          <label className={styles.memberLabel}>Ahmed Khaled</label>
          <label className={styles.memberLabel}>Ahmed Khaled</label>
          <label className={styles.memberLabel}>Ahmed Khaled</label>
          <label className={styles.memberLabel}>Ahmed Khaled</label>
        </div>
        <p className={styles.titleStyle}>Reports</p>
        <div className={styles.reportsDiv}>
          <p className={styles.reportsTitle}>Attendance Report</p>
          <p className={styles.reportsTitle}>Votings Report</p>
          <p className={styles.reportsTitle}>Members Report</p>
        </div>
        <LinkButton className={styles.btnStyle} onClick={() => this.props?.onBtnClick}>Cancel</LinkButton>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.mainDiv}>
        {this.returnFirstTile()}
        {this.returnMiddleTile()}
        {this.returnLastTile()}
      </div>
    );
  }
}

export default ShowMeetingDetailsComponent;
