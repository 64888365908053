import React, { Component, ComponentProps, ComponentPropsWithoutRef } from 'react';
import styles from './LoginPage.module.scss';
import logo from '../../Media/Images/LOGO.png'
import PageContainer from '../../Components/SharedComponents/PageContainer/PageContainer';
import LinkButton from '../../Components/SharedComponents/LinkButton/LinkButton';
import { Link } from 'react-router-dom';
import { Callback } from '../../Components/Callback';
import WebClient from '../../Libs/WebClient/WebClient';
import { AuthActions } from '../../Mobx/Actions/AuthActions';
interface State {
    username?: string
    password?: string
}

class LoginPage extends PageContainer<{}, State> {

    state: Readonly<State> ={

    }

    loginClicked(){
        this.Activity.Show()
        AuthActions
            .Login({
                UserName: this.state.username!,
                Password: this.state.password!
            })
            .onFail( e =>{
                alert(e.response?.data.title)
                this.Activity.Hide()
            })
    }

    updateState(callback:Callback<State>){
        var state = this.state
        callback(state)
        this.setState(state)
    }

    renderContent() {
        return (
            <div className={styles.mainDiv}>
                <div className={styles.loginDiv}>
                    <img src={logo} className={styles.imgStyle}/>
                    <input type='text' placeholder='Username' className={styles.inputFieldStyle}
                        onChange={e => this.updateState(v => v.username = e.target.value)}/>
                    <input type='password' placeholder='Password' className={styles.inputFieldStyle}
                        onChange={e => this.updateState(v => v.password = e.target.value)}/>
                    <LinkButton onClick={() => this.loginClicked()} className={styles.btnStyle}>Login</LinkButton>
                </div>
            </div>
        );
    }
}

export default LoginPage;