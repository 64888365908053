import { makeObservable, observable } from "mobx";
import { hydrateStore } from "mobx-persist-store";
import { Appstore } from "./AppStore";
import { AuthStore } from "./AuthStore";


export class MainStore{
    @observable Auth =  new AuthStore()
    @observable App = new Appstore()

    constructor(){
        makeObservable(this)

        Promise.all([
            hydrateStore('AuthStore')
          ])
          .then(() => this.App.isAppStarted = true)

    }
}


export const GlobalStore = new MainStore()