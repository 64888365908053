import React, { Component, CSSProperties } from 'react';
import styles from './LinkButton.module.css'
import classNames from 'classnames'
import {Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom'
import { Callback } from '../../Callback';
// import {Navigation} from './Navigation';
import { url } from 'inspector';

type Props = {
    onClick?: Callback<React.MouseEvent<HTMLDivElement>>;
    disabled?: boolean
    to?: any
    className?: string
    id?: string
    style?: CSSProperties;
    state?: string
}

class LinkButton extends Component<Props, []> {
    linkRef:any;

    constructor(props: Props) {
        super(props);
        this.linkRef = React.createRef();
    }

    onClickEvent(e: React.MouseEvent<HTMLDivElement>) {
        if (this.props.onClick)
            this.props.onClick(e);
        if (this.props.to && (this.props.disabled == null || this.props.disabled === false)){
            window.location.assign(this.props.to);
            window.history.pushState({state: this.props.state},"",'');
        }
    }

    render() {
        return (
         <div id={this.props.id} style={this.props.style} className={classNames(styles.linkButton, this.props.className, (this.props.disabled)? styles.disabled : null)} onClick={e => this.onClickEvent(e)}>
                <div style={{margin: 'auto', pointerEvents: 'none'}}>{this.props.children}</div>
         </div>
        );
    }
}

export default (LinkButton);