import { AxiosError } from "axios"
import { Callback } from "../Models/Callback"
import { ApiError } from "../Models/Contracts/Common/ApiError"
import { WebClientResponse } from "./WebClientResponse"


export interface IWebClientPromise<T extends any>{
    onComplete(callback : Callback<WebClientResponse<T>>): IWebClientPromise<T>;
    onFail(callback : Callback<AxiosError<ApiError>>): IWebClientPromise<T>;
}



export default class WebClientPromise<T> implements IWebClientPromise<T>{

    private _onComplete: Array<Callback<WebClientResponse>> = []
    private _onFail: Array<Callback<AxiosError>> = []

    public onComplete(callback? : Callback<WebClientResponse<T>>): IWebClientPromise<T>{
        if(callback)
            this._onComplete.push(callback)
        return this
    }

    public onFail(callback? : Callback<AxiosError<ApiError>>): IWebClientPromise<T> {
        if(callback)
            this._onFail.push(callback)
        return this
    }

    public fireOnComplete(response: WebClientResponse): void{
        this._onComplete.forEach(onComplete => onComplete(response))
    }

    public fireOnFail(error: AxiosError){
        this._onFail.forEach(onComplete => onComplete(error))
    }

}
