import React, { Component } from 'react';
import NavBarComponent from '../NavBarComponents/NavBarComponent';
import styles from './NewMeetingComponent.module.scss';
import NewMeetingTiles from './NewMeetingTiles';

class NewMeetingComponent extends Component {


    
    render() {
        return (
            <div>
                 <NavBarComponent navTitle="New Meeting" />
                 <NewMeetingTiles/>
            </div>
        );
    }
}

export default NewMeetingComponent;