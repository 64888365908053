import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './PopupComponent.module.css';
import classnames from 'classnames';

type Props = {
    show: boolean;
    onClose: any;
    popUpContent: any;
    popUpStyle?:any;
    className?:any;
    onExit?:any;
}

class PopupComponent extends Component <Props , {}>{
    constructor(props:Props) {
        super(props);
        this.state = {
            show: this.props.show ?? true
        }
    }
    render() {
        return (
            <div >
                <Modal onHide={this.props.onClose} show={this.props.show} dialogClassName={classnames(styles.contentStyle, this.props.popUpStyle, this.props.className)} onExit={this.props.onExit}>
                    {this.props.popUpContent}
                </Modal>
            </div>
        );
    }
}

export default PopupComponent;
