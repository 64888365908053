import { Environment } from "./Environment";
import WebClient from "../Libs/WebClient/WebClient";

export default class Configuration{

    static Configure(){
        WebClient.SetGlobalBaseUrl(Environment.BaseUrl)
        WebClient.SetGlobalLanguage('en');

        WebClient.Intercept((response) => {
            if(response?.status === 401){
                sessionStorage.clear()
                window.location.href = "/login"
            }
        })
    }
    
}